import { Link } from "gatsby"
import React from "react"

const ProjectCard = ({ title, techs, desc, slug, url, fluid, category }) => {
  return (
    <div className="hover:scale-[1.01] duration-300 w-full shadow-project-card rounded-lg dark:bg-[#222525]">
      <div className="w-full">
        <img
          className={
            "rounded-t-lg shadow-lg " +
            (category === "apps" ? "w-1/4 m-auto" : "w-full")
          }
          src={fluid?.src}
          alt=""
        />
      </div>
      <div className="p-8 sm:p-4">
        <h1 className="text-[#151717] dark:text-[#BDEBEA] font-semibold text-xl sm:text-lg my-2">
          {title}
        </h1>
        <div className="my-2">
          {techs?.length > 0 &&
            techs.map((tech, index) => (
              <p
                key={index}
                className="inline-block mr-4 sm:mr-2 p-2 px-4 sm:px-3 my-1 bg-[#5222D0] dark:bg-darkHeading text-[#DBFFFF] dark:text-[#1F2626] text-xs rounded-md"
              >
                {tech}
              </p>
            ))}
        </div>
        <p className="text-[#1F2626] dark:text-[#BDEBEA] text-justify sm:text-sm my-2">
          {desc}
        </p>
        {category === "templates" ? (
          <a
            className="font-semibold text-[#1F2626] dark:text-[#BDEBEA] sm:text-sm my-2"
            href={`${url}`}
            target="_blank"
          >
            Know more &#10148;
          </a>
        ) : (
          <Link
            className="font-semibold text-[#1F2626] dark:text-[#BDEBEA] sm:text-sm my-2"
            to={`${url}`}
          >
            Know more &#10148;
          </Link>
        )}
      </div>
    </div>
  )
}

export default ProjectCard
