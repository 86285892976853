import * as React from "react"
import { Link, withPrefix } from "gatsby"
import soma from "../images/thumbnail/Soma.png"
import BuggieGarages from "../images/thumbnail/BuggieGarages.png"
import resumeFile from "../documents/rutvik.pdf"

import Layout from "../components/layout"
import ProjectCard from "../components/projects/ProjectCard"
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa"
import { Helmet } from "react-helmet"
import theme from "../components/theme"

const IndexPage = () => {
  const homeProjects = [
    {
      title: "Soma",
      techs: "ReactJS,Redux".split(","),
      slug: "Soma",
      desc: "Soma is e-commerce platform where we provided all the functinalities that required with admin panel to manage products and more.",
      url: "projects/soma",
      fluid: { src: soma },
    },
    {
      title: "Buggie Garages",
      techs: "Angular,Saas,PHP,Mysql".split(","),
      slug: "BuggieGarages",
      desc: "A new emerging start up that is building a network of garages for easy access to the garages around and we contributed in their growing cause.",
      url: "projects/buggie-garages",
      fluid: { src: BuggieGarages },
    },
  ]
  const skills = [
    {
      name: "HTML",
      img: "icons/html.svg",
      value: 4.5,
    },
    {
      name: "CSS",
      img: "icons/css.svg",
      value: 4.5,
    },
    {
      name: "Java Script",
      img: "icons/javascript.svg",
      value: 4.5,
    },
    {
      name: "Angular",
      img: "icons/angular.svg",
      value: 4,
    },
    {
      name: "React JS",
      img: "icons/react.svg",
      value: 3,
    },
    {
      name: "Next JS",
      img: "icons/nextjs.svg",
      value: 2.5,
    },
    {
      name: "Redux",
      img: "icons/redux.svg",
      value: 2.5,
    },
    {
      name: "Node JS",
      img: "icons/node.png",
      value: 2,
    },
    {
      name: "Gatsby",
      img: "icons/gatsby.svg",
      value: 3,
    },
    // {
    //   name: "Git",
    //   img: "icons/git.svg",
    //   value: 4.5,
    // },
    // {
    //   name: "Github",
    //   img: "icons/github.png",
    // },
    // {
    //   name: "Gitlab",
    //   img: "icons/gitlab.svg",
    // },
    {
      name: "PHP",
      img: "icons/php.png",
      value: 3,
    },
    {
      name: "MongoDB",
      img: "icons/mongodb.png",
      value: 2.5,
    },
    {
      name: "MySQL",
      img: "icons/mysql.svg",
      value: 4,
    },
    {
      name: "PostgreSQL",
      img: "icons/postgre.svg",
      value: 3.5,
    },
    {
      name: "Saas",
      img: "icons/saas.svg",
      value: 4.5,
    },
    {
      name: "Tailwind",
      img: "icons/tailwind.svg",
      value: 4.5,
    },
  ]

  return (
    <Layout>
      <Helmet>
        <title>RutvikJR's Portfolio</title>
      </Helmet>
      <div>
        <div>
          <div className="grid grid-cols-2 sm:grid-cols-1 p-16 sm:p-4">
            <div className="flex items-center p-8 sm:p-4">
              <div className="">
                <h5 className="text-[#1F2626] dark:text-[#BDEBEA] text-xl  my-2">
                  DEVELOPER
                </h5>
                <h1 className="text-5xl sm:text-3xl text-[#5222D0] my-2 font-bold dark:text-transparent dark:bg-darkHeading dark:bg-clip-text">
                  Rutvik J Rachhadiya
                </h1>
                <p className="text-justify text-[#1F2626] dark:text-[#BDEBEA] my-2">
                  I love to build static and dynamic websites with a good design
                  using HTML, CSS, JS, and Js libraries and JS frameworks. I am
                  helping startups to grow at scale by building web apps for
                  their businesses. Feel free to contact me, I would love to
                  help you to build your kingdom.
                </p>
                <Link to="/contact">
                  <button className="p-4 py-2 bg-[#5222D0] dark:bg-darkHeading rounded-md text-[#DBFFFF] dark:text-[#1F2626] my-2">
                    Contact Us
                  </button>
                </Link>
              </div>
            </div>
            <div>
              <div className="flex justify-center items-center">
                <img className="dark:hidden" src="images/support.png" />
                <img
                  className="hidden dark:inline-block"
                  src="images/dark.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-20 sm:p-4">
          <h1 className="text-[#151717] sm:p-4 dark:text-[#BDEBEA] font-semibold text-2xl my-4">
            About me
          </h1>
          <div className="border-l-4 border-[#5222D0] dark:border-[#00D9F5]  sm:border-none">
            <p className="text-[#1F2626] dark:text-[#BDEBEA] text-justify m-4">
              I have made my first web project in 2020 and I got my degree at
              PDEU in 2021. I then continued my carrier as a web developer and
              worked on many projects. I worked at Meditab Softwares to build
              their IMS product, I fixed many bugs and also worked on new
              development there. Then I have also worked for Instarent,
              Australia-based startup to fix their web app product. I have
              worked on many small projects. some of them I haven't mentioned
              here. that projects were not that big but because of that projects
              I have learned many things and I am here able to develop web apps
              and helping startups to grow at scale.
            </p>
            <p className="text-[#1F2626] dark:text-[#BDEBEA] text-justify m-4">
              Today I am doing part-time freelancing and also looking for part
              time apportunities to keep updated and improve my technical
              skills.I like to explore new technologies and want to explore more
              in web development field. Feel free to contact me, I would love to
              work with your team and build your products.
            </p>
            {/* <p className="text-[#1F2626] dark:text-[#BDEBEA] text-justify m-4">
              Today I am doing part-time freelancing to keep updated and improve
              my technical skills and also I and my team are helping startups
              and businesses to grow online by the Agency name{" "}
              <a className="font-bold" href="https://axire.in/">
                Axire Infotech
              </a>
              . Feel free to contact us, we would love to build your Entity.
            </p> */}
          </div>
          <Link
            to="http://rutvik.online/documents/rutvik.pdf"
            target={`_blank`}
          >
            <button className="p-4 py-2 bg-[#5222D0] dark:bg-darkHeading rounded-md text-[#DBFFFF] dark:text-[#1F2626] my-2 sm:mx-4">
              <a href={resumeFile} download>
                {" "}
                My Resume
              </a>
            </button>
          </Link>
        </div>
        <div className="p-20 sm:p-8">
          <h1 className="text-[#151717] dark:text-[#BDEBEA] font-semibold text-2xl my-4">
            Projects
          </h1>
          <div className="grid grid-cols-2 sm:grid-cols-1 gap-4">
            {homeProjects.length > 0 &&
              homeProjects.map((project, index) => (
                <ProjectCard {...project} key={index} />
              ))}
          </div>
          <div className="text-center p-8">
            <Link to="/projects">
              <button className="text-[#DBFFFF] dark:text-[#1F2626] px-8 py-2 rounded-md bg-[#5222D0] dark:bg-darkHeading">
                SEE ALL PROJECTS
              </button>
            </Link>
          </div>
        </div>
        <div className="p-20 sm:p-8">
          <h1 className="text-[#151717] dark:text-[#BDEBEA] font-semibold text-2xl my-4">
            Skills
          </h1>
          <div className="grid grid-cols-5 sm:grid-cols-4 gap-4">
            {skills.length > 0 &&
              skills.map((skill, index) => (
                <div
                  className="w-full sm:flex sm:flex-col sm:justify-between"
                  key={index}
                >
                  <div className="w-full flex justify-center">
                    <img className="" src={skill.img} alt={skill?.name} />
                  </div>

                  <p className="text-[#1F2626] dark:text-[#BDEBEA] text-center text-sm sm:text-xs">
                    {skill?.name}
                  </p>
                  <div className="flex justify-center text-[#1F2626] dark:text-[#BDEBEA] text-center text-sm sm:text-xs">
                    <Rating rating={skill?.value} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

const Rating = ({ rating }) => {
  const fullStars = Math.floor(rating)
  const halfStars = rating > fullStars
  const emptyStars = 5 - Math.ceil(rating)
  return (
    <div className="flex justify-center items-center my-1">
      {Array(fullStars).fill(<FaStar className="text-orange-500" />)}
      {halfStars && <FaStarHalfAlt className="text-orange-500" />}
      {Array(emptyStars).fill(<FaRegStar className="text-orange-500" />)}
    </div>
  )
}

export default IndexPage
